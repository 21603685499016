.social-container {
    padding: 25px 50px;
    text-align: center;
  }
  
  a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
  }
  
  a.social:hover {
    transform: translateY(-2px);
  }
  
  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }

  a.linkedin {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: black;
  }

  a.snapchat {
    color: rgb(237, 237, 25);
  }

  a.whatsapp {
    color: rgb(53, 168, 3);
  }