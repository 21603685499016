@media print {

    html,
    body {
        direction: rtl;
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .section {
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        direction: rtl;
    }
}

@media all {
    .page-break {
        display: none;
    }
}


@page {
    size: auto;
    margin: 10mm;
}
 
.allpage{
    direction: rtl;
}  

.button {
    background-color: #7085e6;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 12px;
}

.button:hover {
    background-color: #1824cf;
    color: white;
}

.frame {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    border-radius: 2px;
    z-index: 1;
    margin: 15px;
}

.document {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.section {
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.row {
    display: flex;
    justify-content: space-between;
}

.alternateColor>div:nth-child(even) {
    background: #EEEEEE;
}

td,
th {
    flex: 1;
    padding: 5px;
    min-width: 0px;
    border: 1px solid rgb(207, 200, 200);
}

.logoCell {
    background: url(https://www.nextstepwebs.com/images/logo.png) no-repeat;
    background-size: contain;
}

input {
    width: 40%;
    align-self: flex-start;
    border-radius: 4px;
    line-height: 20px;
    font-size: 13px;

}

.s1 {
    flex: 1;
}

.s2 {
    flex: 2;
}

.s3 {
    flex: 3;
}

.s4 {
    flex: 4;
}

.s5 {
    flex: 5;
}

.s6 {
    flex: 6;
}

.s7 {
    flex: 7;
}

.s8 {
    flex: 8;
}

.s9 {
    flex: 9;
}

.s10 {
    flex: 10;
}

.s11 {
    flex: 11;
}

.s12 {
    flex: 12;
}

.left {
    display: flex;
    justify-content: flex-start;
}

.center {
    display: flex;
    justify-content: center;
}

.right {
    display: flex;
    justify-content: flex-end;
}

.textRight {
    text-align: right;
}

.textCenter {
    text-align: center;
}

.title {
    font-size: 25px;
    font-weight: 700;
}

.boldText {
    font-weight: 700;
}

.taxStamp {
    border: 1px dashed black;
    width: 55mm;
    height: 40mm;
}

.border {
    border: 1px solid black;
}

.borderTop {
    border-top: 1px solid black;
}

.borderLeft {
    border-left: 1px solid black;
}

.borderRight {
    border-right: 1px solid black;
}

.borderBottom {
    border-bottom: 1px solid black;
}

.thin {
    border-width: 1px !important
}

.medium {
    border-width: 2px !important
}

.thick {
    border-width: 3px !important
}

.divider,
.divider1 {
    height: 1px;
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
}

.divider2 {
    height: 2px;
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.divider3 {
    height: 3px;
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
}