@media print {

    html,
    body {
        direction: rtl;
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .section {
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        direction: rtl;
    }
}

 
 .center-image img{
   max-width:100px;
   max-height:100px;
 }