label {
    margin-bottom: 0.3rem;
    font-size: .75rem;
    font-weight: 600;
    color: #383838;
}

span {
    color: #acaaaa;
    font-size: 12px;
}

.inputdisplay {
    color: #0223dd;
    font-size: 12px;
}