@import url('https://fonts.googleapis.com/css2?family=Changa:wght@300&display=swap');
body {
    direction: rtl;
    margin: 0;
    font-family: Changa, sans-serif;
    font-size: .85rem;
  }


  .list-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.text-justify {
  text-align: justify;
}