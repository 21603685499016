@import url(https://fonts.googleapis.com/css2?family=Changa:wght@300&display=swap);

.social-container {
    padding: 25px 50px;
    text-align: center;
  }
  
  a.social {
    margin: 0 1rem;
    transition: -webkit-transform 250ms;
    transition: transform 250ms;
    transition: transform 250ms, -webkit-transform 250ms;
    display: inline-block;
  }
  
  a.social:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
  
  a.youtube {
    color: #eb3223;
  }
  
  a.facebook {
    color: #4968ad;
  }

  a.linkedin {
    color: #4968ad;
  }
  
  a.twitter {
    color: #49a1eb;
  }
  
  a.instagram {
    color: black;
  }

  a.snapchat {
    color: rgb(237, 237, 25);
  }

  a.whatsapp {
    color: rgb(53, 168, 3);
  }
/*=========================================================================*/
/* Footer */
.footer {
    background: #403f3f;
    background: -ms-linear-gradient(top, #403f3f 0%, #383737 100%);
}

.footer ul,
footer p {
    border-right: 1px solid #4f4e4e;
    text-align: right;
    padding-right: 1.25em;
}

.footer ul,
footer p {
    text-align: right;
    padding-right: 1.25em;
}

.footer ul,
footer p {
    font-size: .875em;
    color: #d3d1d1;
    line-height: 3em;
}

.footer a {
    color: #d3d1d1;
}

.footer p {
    font-size: .875em;
    color: #d3d1d1;
    line-height: 1.5em;
}

.footer {
    padding: 1.875em 0 0 0;
}

.footer ul,
footer p {
    min-height: 150px;
    margin-bottom: 1.875em;
}

.footer a:hover,
footer a:focus,
footer p,
abbr {
    color: #fff;
}

.footer ul {
    list-style: none;
}

.footer .shield {
    text-align: center;
}

.footer .shield a {
    text-indent: -9999em;
    display: block;
    width: 90px;
    height: 115px;
    background: transparent url(/static/media/logo.0d460b64.png) 0 0 no-repeat;
    background-size: 100%;
    margin: 0 auto;
    opacity: .6;
    transition: opacity 0.3s ease-in-out;
    overflow: hidden;
}

.footer a:hover,
footer a:focus {
    opacity: 1;
}

.footer hr {
    border: none 0;
    border-top: 1px solid #1b1b1b;
    background: #4f4e4e;
    width: 100%;
    clear: both;
    height: 1px;
    -moz-height: 2px;
    margin: 0 0 16px 0;
}

/*=================================== fieldset  =======================================*/

.fieldset {
    border: 1px solid rgba(122, 123, 121, 0.99);
    padding: 10px;
    width: 100%;
    margin: 15px;
}

legend {
    width: 100%;
    border: 1px solid rgba(140, 142, 140, 0.99);
    background-color: #d9d9d8;
    text-align: center;
}

.divider{ clear:both; min-height:5px;}

.fieldsetcnt {
    border: 1px solid #A68A80;
    padding: 10px;
    width:80%;
}

  label {
      margin-bottom: 0.3rem;
      font-size: .75rem;
      font-weight: 600;
      color: #383838;
  }

  span {
      color: #acaaaa;
      font-size: 12px;
  }

  .inputdisplay {
      color: #0223dd;
      font-size: 12px;
  }
@media print {

    html,
    body {
        direction: rtl;
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .section {
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        direction: rtl;
    }

    table {
        margin-right: 20px;
        margin-left: 20px;
    }
}

@media all {
    .page-break {
        display: none;
    }
}


@page {
    size: auto;
    margin: 20mm;
}


.inputdisplay{
    color: #0223dd;
    font-size:12px;
  }
  
span{
    color: #acaaaa;
    font-size:12px;
  }
 
.allpage{
    direction: rtl;
}  
.button {
    background-color: #7085e6;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 12px;
}

.button:hover {
    background-color: #1824cf;
    /* Green */
    color: white;
}

/* DOCUMENT PARTS */
.frame {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    border-radius: 2px;
    z-index: 1;
    margin: 15px;
}

.document {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.row {
    display: flex;
    justify-content: space-between;
    border: 0;
}

/* .list > div:nth-child(1n+2) {
    border-top: 1px solid lightgray;
  } */

.alternateColor>div:nth-child(even) {
    background: #EEEEEE;
}

td,
th {
    flex: 1 1;
    padding: 5px;
    min-width: 0px;
    border: 0px;
}



input {
    width: 40%;
    align-self: flex-start;
    border-radius: 4px;
    line-height: 20px;
    font-size: 13px;

}

/* CELL SIZES */
.s1 {
    flex: 1 1;
}

.s2 {
    flex: 2 1;
}

.s3 {
    flex: 3 1;
}

.s4 {
    flex: 4 1;
}

.s5 {
    flex: 5 1;
}

.s6 {
    flex: 6 1;
}

.s7 {
    flex: 7 1;
}

.s8 {
    flex: 8 1;
}

.s9 {
    flex: 9 1;
}

.s10 {
    flex: 10 1;
}

.s11 {
    flex: 11 1;
}

.s12 {
    flex: 12 1;
}

/* ALIGN THE CELL */
.left {
    display: flex;
    justify-content: flex-start;
}

.center {
    display: flex;
    justify-content: center;
}

.right {
    display: flex;
    justify-content: flex-end;
}

/* ALIGNT TEXT INSIDE THE CELL */
.textRight {
    text-align: right;
}

.textCenter {
    text-align: center;
}

/* TEXT TYPE */
.title {
    font-size: 25px;
    font-weight: 700;
}

.boldText {
    font-weight: 700;
}

.taxStamp {
    border: 1px dashed black;
    width: 55mm;
    height: 40mm;
}

/* BORDERS */
.border0 {
    border: 0px;
}

.borderAll {
    border: 1px solid black;
}

.borderTop {
    border-top: 1px solid black;
}

.borderLeft {
    border-left: 1px solid black;
}

.borderRight {
    border-right: 1px solid black;
}

.borderBottom {
    border-bottom: 1px solid black;
}

.thin {
    border-width: 1px !important
}

.medium {
    border-width: 2px !important
}

.thick {
    border-width: 3px !important
}

/* DIVIDERS */
.divider,
.divider1 {
    height: 1px;
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
}

.divider2 {
    height: 2px;
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.divider3 {
    height: 3px;
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
}
label {
    margin-bottom: 0.3rem;
    font-size: .75rem;
    font-weight: 600;
    color: #383838;
}

span {
    color: #acaaaa;
    font-size: 12px;
}

.inputdisplay {
    color: #0223dd;
    font-size: 12px;
}
@media print {

    html,
    body {
        direction: rtl;
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .section {
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        direction: rtl;
    }
}

@media all {
    .page-break {
        display: none;
    }
}


@page {
    size: auto;
    margin: 10mm;
}
 
.allpage{
    direction: rtl;
}  

.button {
    background-color: #7085e6;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 12px;
}

.button:hover {
    background-color: #1824cf;
    color: white;
}

.frame {
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
    border-radius: 2px;
    z-index: 1;
    margin: 15px;
}

.document {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.section {
    padding: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}


.row {
    display: flex;
    justify-content: space-between;
}

.alternateColor>div:nth-child(even) {
    background: #EEEEEE;
}

td,
th {
    flex: 1 1;
    padding: 5px;
    min-width: 0px;
    border: 1px solid rgb(207, 200, 200);
}

.logoCell {
    background: url(https://www.nextstepwebs.com/images/logo.png) no-repeat;
    background-size: contain;
}

input {
    width: 40%;
    align-self: flex-start;
    border-radius: 4px;
    line-height: 20px;
    font-size: 13px;

}

.s1 {
    flex: 1 1;
}

.s2 {
    flex: 2 1;
}

.s3 {
    flex: 3 1;
}

.s4 {
    flex: 4 1;
}

.s5 {
    flex: 5 1;
}

.s6 {
    flex: 6 1;
}

.s7 {
    flex: 7 1;
}

.s8 {
    flex: 8 1;
}

.s9 {
    flex: 9 1;
}

.s10 {
    flex: 10 1;
}

.s11 {
    flex: 11 1;
}

.s12 {
    flex: 12 1;
}

.left {
    display: flex;
    justify-content: flex-start;
}

.center {
    display: flex;
    justify-content: center;
}

.right {
    display: flex;
    justify-content: flex-end;
}

.textRight {
    text-align: right;
}

.textCenter {
    text-align: center;
}

.title {
    font-size: 25px;
    font-weight: 700;
}

.boldText {
    font-weight: 700;
}

.taxStamp {
    border: 1px dashed black;
    width: 55mm;
    height: 40mm;
}

.border {
    border: 1px solid black;
}

.borderTop {
    border-top: 1px solid black;
}

.borderLeft {
    border-left: 1px solid black;
}

.borderRight {
    border-right: 1px solid black;
}

.borderBottom {
    border-bottom: 1px solid black;
}

.thin {
    border-width: 1px !important
}

.medium {
    border-width: 2px !important
}

.thick {
    border-width: 3px !important
}

.divider,
.divider1 {
    height: 1px;
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
}

.divider2 {
    height: 2px;
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 20px;
}

.divider3 {
    height: 3px;
    background-color: black;
    margin-left: 15px;
    margin-right: 15px;
}
@media print {

    html,
    body {
        direction: rtl;
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .section {
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        direction: rtl;
    }
}

 
 .center-image img{
   max-width:100px;
   max-height:100px;
 }
@media print {

    html,
    body {
        direction: rtl;
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
    }

    .section {
        padding-top: 30px;
        padding-left: 50px;
        padding-right: 50px;
        padding-bottom: 30px;
        direction: rtl;
    }
}

 
 .center-image img{
   max-width:100px;
   max-height:100px;
 }
body {
    direction: rtl;
    margin: 0;
    font-family: Changa, sans-serif;
    font-size: .85rem;
  }


  .list-card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.text-justify {
  text-align: justify;
}
