/*=========================================================================*/
/* Footer */
.footer {
    background: #403f3f;
    background: -moz-linear-gradient(top, #403f3f 0%, #383737 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #403f3f), color-stop(100%, #383737));
    background: -webkit-linear-gradient(top, #403f3f 0%, #383737 100%);
    background: -o-linear-gradient(top, #403f3f 0%, #383737 100%);
    background: -ms-linear-gradient(top, #403f3f 0%, #383737 100%);
}

.footer ul,
footer p {
    border-right: 1px solid #4f4e4e;
    text-align: right;
    padding-right: 1.25em;
}

.footer ul,
footer p {
    text-align: right;
    padding-right: 1.25em;
}

.footer ul,
footer p {
    font-size: .875em;
    color: #d3d1d1;
    line-height: 3em;
}

.footer a {
    color: #d3d1d1;
}

.footer p {
    font-size: .875em;
    color: #d3d1d1;
    line-height: 1.5em;
}

.footer {
    padding: 1.875em 0 0 0;
}

.footer ul,
footer p {
    min-height: 150px;
    margin-bottom: 1.875em;
}

.footer a:hover,
footer a:focus,
footer p,
abbr {
    color: #fff;
}

.footer ul {
    list-style: none;
}

.footer .shield {
    text-align: center;
}

.footer .shield a {
    text-indent: -9999em;
    display: block;
    width: 90px;
    height: 115px;
    background: transparent url(../../assets/images/logo.png) 0 0 no-repeat;
    background-size: 100%;
    margin: 0 auto;
    opacity: .6;
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    overflow: hidden;
}

.footer a:hover,
footer a:focus {
    opacity: 1;
}

.footer hr {
    border: none 0;
    border-top: 1px solid #1b1b1b;
    background: #4f4e4e;
    width: 100%;
    clear: both;
    height: 1px;
    -moz-height: 2px;
    margin: 0 0 16px 0;
}