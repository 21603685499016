
/*=================================== fieldset  =======================================*/

.fieldset {
    border: 1px solid rgba(122, 123, 121, 0.99);
    padding: 10px;
    width: 100%;
    margin: 15px;
}

legend {
    width: 100%;
    border: 1px solid rgba(140, 142, 140, 0.99);
    background-color: #d9d9d8;
    text-align: center;
}

.divider{ clear:both; min-height:5px;}

.fieldsetcnt {
    border: 1px solid #A68A80;
    padding: 10px;
    width:80%;
}
